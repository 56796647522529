import React from 'react';
import { Typography} from "antd";
import UserIcon from '../assets/user-profile-icon.svg';

function HeaderUser({userName, userText}:any) {
    const { Text } = Typography;
    return (
        <div className="invoices-application-box">
            <div className="invoices-application-icon">
                <img src={UserIcon} width="20" height="20" alt="icon" />
            </div>
            <div className="invoices-application-txt">
                <Text style={{ fontWeight: '600', lineHeight: '30px' }}>
                    Merhaba {userName},
                </Text>
                <Text>
                    {userText}
                </Text>
            </div>
        </div>
    );
}

export default HeaderUser;
