import React, { useEffect, useState } from "react";
import { Table,  Button } from "antd";
import { useNavigate } from "react-router-dom";
import { setBuyersListApi,getBuyersTokenApi} from '../services/userAuth';
import { LocalStorageService } from '../helpers/LocalStorage';

const BuyerList = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [buyerList, setBuyerList] = useState([]);
  const [buyerId, setBuyerId] = useState('');

  const BuyerList = async () => {
    setLoading(true);
    try{
      const response:any = await setBuyersListApi();
      if(response){
        setBuyerList(response.buyers);
        setLoading(false);
      }
    }catch(e:any){
      console.log(e);
      setLoading(false);
    }
  }

  const BuyerToken = async (buyerId:any) => {
    try{
      const response:any = await getBuyersTokenApi(buyerId);
      if(response){
        LocalStorageService.setAuthToken(response.token);
        LocalStorageService.setTabKey('1')
        navigate("/invoices-application");
      }
    }catch(e:any){
      console.log(e);
    }
  }


  const rowSelection = {
    onChange:  (selectedRowKeys:any, selectedRows:any) => {
        if (selectedRowKeys.length > 0) {
            const buyerIData = selectedRows.reduce((newData:any, row:any) => {               
                newData.push({
                    id: row.id,
                    title: row.title,
                });             
                return newData;
            }, []);
            setBuyerId(buyerIData[0].id)
        } 
    },
  };

  const setActiveBuyer = () => {
    BuyerToken(buyerId)
  }

  useEffect(() => {
    BuyerList();
  },[])

  const columns:any = [
    {
      title: 'Alıcı VKN',
      dataIndex: 'taxId',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Alıcı Ünvanı',
      dataIndex: 'title',
    }
  ];
 

  return (
    <>
      <div>
        <h3 className='noti-row'>Lütfen işlem yapmak istediğiniz alıcıyı seçiniz..</h3>
      </div>
      <div>

        <Table
          className="w-100 invoiceTable"
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={buyerList}
          loading={loading}
          rowKey="id"
          scroll={{
            x: 450,
          }}
        />
      </div>
      <div>
        <Button type="primary" onClick={setActiveBuyer} loading={loading} >Seçili Alıcı ile Devam et</Button>
      </div>
    </>
  );
};

export default BuyerList;
