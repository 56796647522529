import React, {useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Spin, Modal, Button, Tabs} from 'antd';
import { onLoginCodeApi } from '../services/userAuth';
import ClarificationText from './Documents/ClarificationText';
import ExplicitConsentText from './Documents/ExplicitConsentText'; 
import MembershipAgreement from './Documents/MembershipAgreement';
import { LocalStorageService } from '../helpers/LocalStorage';

const { TabPane } = Tabs;


const LoginByCode = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const urlData = location.pathname.split('/');
  const authorizationCode = urlData[2];
  const requestId = urlData[4];
  const [loading, setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabShow, setTabShow] = useState(0); 


  const handleStay = () => {
    setIsModalOpen(false);
    navigate("/buyer-list");
  }

  const onLoginCode = async () => {
    try{
      setloading(true)
      const response:any = await onLoginCodeApi(authorizationCode,requestId)
      LocalStorageService.setPlatformCode(response?.platformCode);

      if(response){
        setloading(false);
        if(response?.isInitialLogin){
          setIsModalOpen(true);
          LocalStorageService.setAuthToken(response.token);
          LocalStorageService.setUserName(response?.nameSurname);
        }else{
          LocalStorageService.setAuthToken(response.token);
          navigate("/buyer-list");
        }

      }
    }catch(e:any){
      console.log('e',e)
      if (e.data.Status === 404 && e.data.Type.includes('UserNotFoundException')) {
        navigate("/not-user");
      }
    }
  }

  const onChangeTab = (key:any) => {
    setTabShow(key)
  };


  useEffect(() => {
    onLoginCode();
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  return (
    <div className="login-loading" >
      <Spin spinning={loading} tip="Lütfen Bekleyiniz..." size="large" >
        <div style={{width:'100%', height:'100vh', display:'flex'}}></div>
      </Spin>
      <Modal className='contract-modal-style' 
          open={isModalOpen} 
          maskClosable={false}
          closable={false} 
          footer={[
            <Button key="back" type="primary"  onClick={handleStay}>
              Okudum Onayladım
            </Button>
          ]}
          >
        <Tabs activeKey={`${tabShow}`} onChange={onChangeTab}>
          <TabPane tab="Üyelik Sözleşmesi" key='0'>
            <MembershipAgreement />
          </TabPane>
          <TabPane tab="Aydınlatma Metni" key='1'>
            <ClarificationText />
          </TabPane>
          <TabPane tab="Açık Rıza Metni" key='2'>
            <ExplicitConsentText />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
    
  )
};
export default LoginByCode;
