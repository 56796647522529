
import { PDFDocument, rgb} from 'pdf-lib';

import moment from 'moment';
import fontkit from '@pdf-lib/fontkit';
import { fileTypes } from '../helpers/common';
import Temlikname from './temlikname.pdf';
import NotoFont from './NotoSerif-Regular.ttf';
import  {conveyanceMailsApi}  from '../services/invoices';
// import PlatformuKullanım from '../assests/Uyelik_sozlesmesi.pdf';
import { convertFloatDotSeperated } from "../helpers/common";


// const writtenNumber = require('written-number');

export const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const getIsFilePdf = (fileName) => fileName && fileName.type && fileName.type.endsWith('pdf');
export const getFileType = (file) => {
    const type = (file && file.type) || '';
    const splitted = type.split('/');
    return splitted[splitted.length - 1];
};
export const getFileTypeByUrl = (fileUrl = '') => {
    const splittedUrl = fileUrl.split('.');
    const type = splittedUrl[splittedUrl.length - 1];

    if (Object.values(fileTypes).includes(type)) {
        return type;
    }
    return '';
};

export const downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
const toBase64 = (u8) => btoa(u8.reduce((data, byte) => data + String.fromCharCode(byte), ''));

const printCol = (page, font, textSize, text, textx, texty, rectx, recty, textWidth, textHeight) => {
    page.drawText(text, {
        x: textx,
        y: texty,
        size: textSize,
        font,
        // color: rgb(0, 0.53, 0.71),
        color: rgb(0, 0, 0, 1),
    });
    page.drawRectangle({
        x: rectx,
        y: recty,
        width: textWidth,
        height: textHeight,
        borderColor: rgb(0, 0, 0, 1),
        borderWidth: 0.5,
    });
};


const size = 6;
const textY = 718;
const rectY = 712; 
const colH = 16;

const colW1 = 28.2;
const colW2 = 42;
const colW3 = 64;
const colW4 = 50;
const colW5 = 99;
const colW6 = 35;
const colW7 = 57;
const colW8 = 56;
const colW9 = 51;
const colW10 = 78.2;

const printRows = (page, font, invoicesData, info ,x ) => {
   let sayi = 0 ;
   const xValue = x !== undefined ? x : 0
   const newValue = 40 * xValue;

    invoicesData &&
    invoicesData.map(({ invoiceDate, invoiceTerm, invoiceNumber, invoiceTotal }, index) => {
            const invoDate = moment(invoiceDate).format('DD-MM-YYYY');
            const termDate = moment(invoiceTerm).format('DD-MM-YYYY');
            const total = `${convertFloatDotSeperated(invoiceTotal)}`;
           
            const txtY = textY - colH * index;
            const rctY = rectY - colH * index;

            const next = index + 1
            sayi = newValue + next ;
            
            /* eslint-disable no-nested-ternary */
            printCol(page, font, size, sayi <10 ? `00${sayi.toString()}`: sayi<100 ? `0${sayi.toString()}`: sayi.toString()  , 74, txtY, 70, rctY, colW1, colH);
          
            printCol(page, font, size, invoDate, 100, txtY, 98.2, rctY, colW2, colH);
            printCol(page, font, size, invoiceNumber, 142, txtY, 140, rctY, colW3, colH);
            printCol(page, font, size, info.buyerTaxNumber, 206, txtY, 204, rctY, colW4, colH);
            printCol(page, font, size, `${info?.buyerTitle.substring(0, 70)}..` , 256, txtY, 254, rctY, colW5, colH);
            printCol(page, font, size, 'TL', 366, txtY, 353, rctY, colW6, colH);
            printCol(page, font, size, total, 391, txtY, 388, rctY, colW7, colH);
            printCol(page, font, size, total, 447, txtY, 445, rctY, colW8, colH);
            printCol(page, font, size, termDate, 503, txtY, 501, rctY, colW9, colH);

            return index;
        });
};

const printLastRows = (page, font, invoices, invoTotal) => {
  
    const txtY = textY - colH * invoices.length;
    const rctY = rectY - colH * invoices.length;
    const total = `${convertFloatDotSeperated(invoTotal)}`;

    printCol(page, font, size, 'TOPLAM TL', 330, txtY, 310, rctY, colW10, colH);
    printCol(page, font, size, `${total}`, 391, txtY, 388, rctY, colW7, colH);
    printCol(page, font, size, `${total}`, 448, txtY, 445, rctY, colW8, colH);
};


export const LoadPdfTemlik = async (formData, btn, emailData) => {
  
    const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
    const existingPdfBytes = await fetch(Temlikname)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
    if (existingPdfBytes) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
            updateMetadata: false,
        });

        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes)
    
        // console.log(writtenNumber(35.000,50, {lang: 'tr'}));
        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const invoices = formData?.invoices?.length > 0 && formData?.invoices.filter(item => item.status === 'SECILDI');
      
        const dataLoop = Math.ceil(invoices.length/40);
       
        firstPage.moveTo(152, 127);
        firstPage.drawText(formData.agriculturistTitle, {
            size: 11,
            font: customFont,
        });

        firstPage.moveTo(269, 38);
        firstPage.drawText('Kaşe + İmza', {
            size: 8,
            font: customFont,
        });

        pages[1].moveTo(269, 38);
        pages[1].drawText('Kaşe + İmza', {
            size: 8,
            font: customFont,
        });

        firstPage.moveTo(70, 140);
        firstPage.drawText(moment().format('DD-MM-YYYY'), {
            size: 10,
            font: customFont,
        });

        const data = [];
        let startNumber = 0;
        let numberPage = 40;
        let i = 0;
       
        do {
            data.push(invoices.slice(startNumber,numberPage))
            startNumber += 40 ;
            numberPage += 40
            i += 1
          } while (i < dataLoop);

        const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0)

        if(data.length > 0 ){
            printRows(pages[1], customFont, data[0], formData)
            data.length === 1 &&  printLastRows(pages[1], customFont, data[data.length-1], selectedInvoiceTotal);

            if(data.length !== 1){
                let x = 1;
                do {
                   
                    const pagex =  pdfDoc.addPage();
                     printRows(pagex, customFont, data[x], formData, x)
                     pagex.moveTo(269, 38);
                     pagex.drawText('Kaşe + İmza', {
                         size: 8,
                         font: customFont,
                     });
                      x += 1;
                      if(x === dataLoop ){
                          printLastRows(pagex, customFont, data[data.length-1], selectedInvoiceTotal);
                          pagex.moveTo(269, 38);
                          pagex.drawText('Kaşe + İmza', {
                              size: 8,
                              font: customFont,
                          });
                      }
                  } while (x < dataLoop); 

            }
        }

      const fileName = `Temlikname-${((formData.financialInstitutionName).split(' ')[0])}-${formData.number}.pdf`; 
      if(btn === 'indir'){
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        downloadURI(pdfDataUri, `Temlikname-${((formData.financialInstitutionName).split(' ')[0])}-${formData.number}`);
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const updatedPdf = await pdfDoc.save();
    if(btn === 'email'){
        const mailData = {
            base64File: toBase64(updatedPdf),
            fileName: fileName,
            email: emailData
        }
        await conveyanceMailsApi(formData.id,mailData)
    }
    
        
        return toBase64(updatedPdf);
    }
};
// export const LoadPdfPlatform = async (text) => {
//     const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
//     const existingPdfBytes = await fetch(PlatformuKullanım)
//         .then((res) => res.arrayBuffer())
//         .then((arrayBufferData) => arrayBufferData);
//     if (existingPdfBytes) {
//         const pdfDoc = await PDFDocument.load(existingPdfBytes, {
//             updateMetadata: false,
//         });

//         pdfDoc.registerFontkit(fontkit);
//         const customFont = await pdfDoc.embedFont(fontBytes)

//         // Get the first page of the document
//         const pages = pdfDoc.getPages();
//         const firstPage = pages[11];

//         const title = text.split(' ')
//         const pageNumber = [];
//         let x=0;
//         let y=0;
//         let z=0;

//         do {
//             pageNumber[y] = title.slice(y, y+3).join(' ')
             
//             firstPage.moveTo(76, 460 - z );
//             firstPage.drawText( pageNumber[y] , {
//                 font: customFont,
//                     size: 10,
//                 });
            
//             x += 3;
//             z += 10;
//             y += 3;
//           } while (x < title.length);

//           firstPage.moveTo(485, 525 );
//           firstPage.drawText(moment().format('DD-MM-YYYY'), {
//               size: 10,
//               font: customFont,
//           });

     
//         const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
//             downloadURI(pdfDataUri, 'Üyelik Sözleşmesi');
      

//         // Serialize the PDFDocument to bytes (a Uint8Array)
//         const updatedPdf = await pdfDoc.save();
//         return toBase64(updatedPdf);
//     }
// };

