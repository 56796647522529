
import React, { useState } from 'react';
import { Button, Typography, Modal, notification } from 'antd';
import { convertFloatDotSeperated } from "../helpers/common";
import { createDiscountsApi } from '../services/invoices';

function AmountReceived({ calculatedDiscount, total, invoices, setTabKey }: any) {
   const { Text } = Typography;
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [applicationNumber, setApplicationNumber] = useState('');

   const handleClick = async () => {
      const newData = {
         buyer: {
            id: invoices[0].buyerId,
            title: invoices[0].buyerTitle,
            taxId: invoices[0].buyerTaxNumber,
         },
         averageMaturity: calculatedDiscount.averageMaturity,
         invoices: invoices.map((item: any) => {
            return (
               {
                  id: item.id,
                  date: item.invoiceDate,
                  fileName: item.fileName,
                  number: item.invoiceNumber,
                  hashCode: item.hashCode,
                  total: item.invoiceTotal,
                  currency: item.currency,
                  invoiceType: item.invoiceType,
                  term: item.invoiceTerm,
                  isApproved: false,
                  createdDate: item.createdDate
               }
            )
         }),
         financialInstitutions: {
            id: calculatedDiscount.financialInstitutionId,
            name: calculatedDiscount.financialInstitutionName
         }
      };

      if (newData && Object.keys(newData).length > 0) {
         try {
            const response: any = await createDiscountsApi(newData);
            setApplicationNumber(response.applicationNumber);
            setIsModalOpen(true);
            setTimeout(() => {
               setIsModalOpen(false);
               window.location.reload(); 
             }, 8000); 
         } catch (e: any) {
            console.log(e);

            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughAgriculturistLimitException')) {
               notification.error({ message: 'Çiftçi limitiniz yetersiz.Lütfen alıcınızla iletişime geçin.' });
            }
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughBuyerLimitException')) {
               notification.error({ message: 'Alıcınızın limiti yetersiz. Lütfen alıcınızla irtibata geçin.' });
            }
         }
      }
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      setTabKey('2')
   };

   return (
      <>
         <div className='amount-received'>
            <div className='amount-received-txt' >
               <Text className='amount-received-t1'>Elinize Geçecek Tutar:</Text>
               <Text className='amount-received-t2'>{total !== null || total !== '' ? `${convertFloatDotSeperated(total)} TL` : ''}</Text>
            </div>
            <Button onClick={handleClick} className='amount-received-link'>Denizbank'a Hemen Başvur!</Button>
         </div>
         <Modal
            closable={false}
            open={isModalOpen}
            footer={[
               <Button key="ok" type="primary" onClick={handleCancel}>
                  Kapat
               </Button>
            ]}
         >
            <p>{applicationNumber} numaralı başvurunuz başarıyla oluşturuldu. Başvurunuzun durumunu "Başvurularım" sekmesinden takip edebilirsiniz.</p>
         </Modal>
      </>
   );
}

export default AmountReceived;

