export class LocalStorageService {
    static authTokenKey: string = 'token-auth';
    static province: string = 'province';
    static platformCode: string = 'platformCode';
    static userName: string = 'userName';
    static tabKey: string = '1';

    // Token

    static getAuthToken() {
        return localStorage.getItem(this.authTokenKey);
    }

    static setAuthToken(token?: string | any) {
        localStorage.setItem(this.authTokenKey, token);
    }

    static removeAuthToken() {
        localStorage.removeItem(this.authTokenKey);
    }

    // platformCode

    static setPlatformCode(platformCode?: string | any) {
        localStorage.setItem(this.platformCode, platformCode);
    }

    static getPlatformCode() {
        return localStorage.getItem(this.platformCode);
    }

    static removePlatformCode() {
        localStorage.removeItem(this.platformCode);
    }

    // province

    static setProvince(data?: string | any) {
        localStorage.setItem(this.province, data);
    }
    static getProvince() {
        return localStorage.getItem(this.province);
    }
    static removeProvince() {
        localStorage.removeItem(this.province);
    }

    // User Name

    static setUserName(data?: string | any) {
        localStorage.setItem(this.userName, data);
    }
    static getUserName() {
        return localStorage.getItem(this.userName);
    }
    static removeUserName() {
        localStorage.removeItem(this.userName);
    }

    // Tab Key

    static setTabKey(data?: string | any) {
        localStorage.setItem(this.tabKey, data);
    }
    static getTabKey() {
        return localStorage.getItem(this.tabKey);
    }
    static removeTabKey() {
        localStorage.removeItem(this.tabKey);
    }



}