
import {api} from '../services/apis';

const loginApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`authorize`, data)
  );
};

const sendOtpCode = async (taxId: any, code:any): Promise<Object> => {
  return (
    await api.post(`otp/validate`, {taxId, code})
  );
};

const onLoginCodeApi = async (code: any, requestId:any): Promise<Object> => {
  return (
    await api.post(`authorize/code`, {code, requestId})
  );
};

const setBuyersListApi = async (): Promise<Object> => {
  return await api.get(`agriculturists/sessions/buyers`,{}
  );
};

const getBuyersTokenApi = async (id: any): Promise<Object> => {
  return (
    await api.post(`agriculturists/sessions/buyers/${id}`)
  );
};


export {
    loginApi,
    sendOtpCode,
    onLoginCodeApi,
    setBuyersListApi,
    getBuyersTokenApi
}