import React from "react";
import { Outlet } from 'react-router-dom';
import Footer from "../components/Footer";
import Header from "../components/Header";


const Home = () => {
  


  return (
    <>
      <Header/>
        <main>
          <div className="content-main">
            <Outlet />
          </div>
      </main>
      <Footer />

    </>
  )
};
export default Home;
