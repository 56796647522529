/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
  },
};
  
const urls = {
  login: '/login',
  home: '/',
  BuyerList: '/buyer-list',
  InvoicesApplication: '/invoices-application',
  passwordCreate: '/password/forget',
  forgetPassword: '/forgetpassword',
  loginByCode: '/authorizationCode/:AuthorizationCode/requestId/:RequestId',
  notUser: '/not-user',
  applicationDetail: '/application-detail/:id',
};

export  { fakeAuthProvider, urls };
  