import React from 'react';
import HelpLink from './HelpLink';
// import AmountReceived from './AmountReceived';

function Footer() {
    return (
     <footer>
        <HelpLink/>
        {/* <AmountReceived/> */}
     </footer>
    );
}

export default Footer;
