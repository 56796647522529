import { createSlice } from '@reduxjs/toolkit';

interface TabState {
  activeTab: number;
  platformCode: number;
}

const initialState = {
  activeTab :0,
  platformCode:0
} as TabState;

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    activeTab(state,action){
      state.activeTab = action.payload
    },
    setPlatformCode(state,action){
      state.platformCode = action.payload
    }
  },
});

export const {  activeTab, setPlatformCode } = tabSlice.actions;
export default tabSlice.reducer;
