import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { fakeAuthProvider, urls } from "./auth";
import { LocalStorageService } from '../helpers/LocalStorage';
import Home from "../pages/Home";
import InvoicesApplication from "../pages/InvoicesApplication";
import ApplicationDetail from "../pages/ApplicationDetail";
import LoginByCode from "../pages/LoginByCode";
import Login from "../pages/Login";
import NotUser from "../pages/NotUser";
import BuyerList from "../pages/BuyerList";

const AppRouter = () => {
 
  return (
    <AuthProvider>
      <Routes>
        <Route path={urls.login} element={<Login />} />
        <Route path={urls.loginByCode} element={<LoginByCode />} />
        <Route path={urls.notUser} element={<NotUser />} />
        <Route path='/' 
          element={ 
          <RequireAuth>
              <Home/>
            </RequireAuth>
          }
        >
         <Route
            path={urls.BuyerList}
            element={
              <RequireAuth>
                <BuyerList />
              </RequireAuth>
            }
          />
         <Route
            path={urls.InvoicesApplication}
            element={
              <RequireAuth>
                <InvoicesApplication />
              </RequireAuth>
            }
          />
           <Route
            path={urls.applicationDetail}
            element={
              <RequireAuth>
                <ApplicationDetail />
              </RequireAuth>
            }
          />

        </Route>
      </Routes>
    </AuthProvider>
  );
};

interface AuthContextType {
  token: any;
  signin: (token: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<any>(null);

  useEffect(() => {
    const token = LocalStorageService.getAuthToken();
    token && LocalStorageService.setAuthToken(token);
  }, []);

  const signin = (newToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(newToken);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  const value = { token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();

  if (!auth.token && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (location.pathname === "/") {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to="/invoices-application" state={{ from: location }} replace />
    );
  }

  return children;
}




export default AppRouter;
