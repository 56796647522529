import React from "react";
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


const NotUser = () => {
  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate('/login'); 
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Result
        status="404"
        title="Kullanıcı Bulunamadı"
        subTitle={
          <>
            Alıcınız sizleri tanımlamadığı için giriş yapamıyorsunuz. <br />
            Lütfen bizimle <a href="mailto:destek@depar.io" style={{ color: '#1890ff', cursor: 'pointer' }}>
            destek@depar.io
            </a>. ya da 
            <br />
           <a href="tel:+5348123264" style={{ color: '#1890ff', cursor: 'pointer' }}>0534 812 32 64 </a> numaralı telefondan iletişime geçin.
          </>
        }
        extra={
          <Button type="primary" onClick={goToLoginPage}>
            Giriş Sayfası
          </Button>
        }
      />
    </div>
    
  )
};
export default NotUser;
