
import {api} from '../services/apis';

const getInvoiceApi = async (page: any, psize: any): Promise<Object> => {
  return await api.get(`invoices?pageNumber=${page}&pageSize=${psize}`,
    {}
  );
};

const calculateTotalApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`discounts/calculate`,data)
  );
};

const getDiscountsApi = async (page: any, psize: any): Promise<Object> => {
  return await api.get(`discounts?pageNumber=${page}&pageSize=${psize}`,
    {}
  );
};

const createDiscountsApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`discounts`,data)
  );
};

const getDiscountsDetailApi = async (discountId: any): Promise<Object> => {
  return await api.get(`discounts/${discountId}`,
    {}
  );
};

const discountsAcceptApi = async (discountId: any, financeId: any): Promise<Object> => {
  return (
    await api.patch(`offers/discounts/${discountId}/finance/${financeId}/accept`)
  );
};

const conveyanceMailsApi = async (discountId:any ,data: any): Promise<Object> => {
  return (
    await api.post(`discounts/${discountId}/conveyance/mails`,data)
  );
};

const sendDelivered = async (discountId: any): Promise<Object> => {
  return (
    await api.post(`discounts/${discountId}/conveyance`)
  );
};

export {
  getInvoiceApi,
  calculateTotalApi,
  getDiscountsApi,
  createDiscountsApi,
  getDiscountsDetailApi,
  discountsAcceptApi,
  conveyanceMailsApi,
  sendDelivered
}