import React, {useState} from 'react';
import { Button, Modal, Result } from 'antd';

function HelpLink() {
    const [isModalOpen,setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(true)
    } 

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
          <Button onClick={handleClick} className='help-div'>
            Yardıma İhtiyacınız Mı Var?
        </Button>
         <Modal
         closable={false}
         open={isModalOpen}
         footer={[
            <Button key="ok" type="primary" onClick={handleCancel}>
               Kapat
            </Button>
         ]}
      >
        <Result
        status={403}
        title=" Yardıma İhtiyacınız Mı Var?"
        subTitle={
          <>
           Bizimle <a href="mailto:destek@depar.io" style={{ color: '#1890ff', cursor: 'pointer' }}>
            destek@depar.io </a>e-posta adresinden ya da <a href="tel:+5348123264" style={{ color: '#1890ff', cursor: 'pointer' }}>0534 812 32 64 </a> numaralı telefondan iletişime geçebilirsiniz.
          </>
        }
      />
      </Modal>
        </> 
        
    );
}

export default HelpLink;
