import React, {useState} from "react";
import { Form, Input, Button, Card, Checkbox , Modal, Tabs, notification} from 'antd';
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import ClarificationText from './Documents/ClarificationText';
import ExplicitConsentText from './Documents/ExplicitConsentText'; 
import MembershipAgreement from './Documents/MembershipAgreement';
import {validateVkn} from '../helpers/validators';
import { InputOTP } from "antd-input-otp";
import {loginApi, sendOtpCode} from '../services/userAuth';
import { LocalStorageService } from '../helpers/LocalStorage';
import logo from '../assets/logo.png'

const { TabPane } = Tabs;

const Login = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [register, setRegister] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabShow, setTabShow] = useState(1);
  const [loading, setloading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [taxId, setTaxId] = useState('');
  const [isInitialLogin, setIsInitialLogin] = useState(false);


  const onFinish = async (values: any) => {
    setloading(true)
    setTaxId(values.taxId);
    try {
      const response: any = await loginApi(values);
      if (response) {
        setloading(false)
        setOtpModal(true);
      }
    } catch (e: any) {
      console.log('e', e);
      setloading(false);
      if (e.data.Status === 404 && e.data.Type.includes('UserNotFoundException')) {
        navigate("/not-user");
      }
    }
  };


  const onSubmitOtpCode = async (value: any) => {
    try {
      setloading(true)
      const otpCode = value.otp.join("");
      const response: any = await sendOtpCode(taxId, otpCode)
      if (response) {
        setloading(false)
        setOtpModal(false);
        LocalStorageService.setPlatformCode(response?.platformCode);
        LocalStorageService.setUserName(response?.nameSurname);
        if(response?.isInitialLogin){
          setIsInitialLogin(response?.isInitialLogin);
          LocalStorageService.setAuthToken(response.token);
          setRegister(true);
        } else {
          LocalStorageService.setAuthToken(response.token);
          navigate("/buyer-list");
        }
      }
    } catch (e: any) {
      console.log('e', e);
      form.resetFields();
      notification.warning({
        message: `Girmiş olduğunuz kodu yanlış. Lütfen tekrar deneyiniz.`,
      });
      setloading(false)
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setOtpModal(false);
  };

  const onChangeTab = (key: any) => {
    setTabShow(key)
  };

  const showModal = (keyX: any) => {
    setIsModalOpen(true);
    setTabShow(keyX)
  };

  const handleStay = () => {
    setIsInitialLogin(true);
    setIsModalOpen(false);
    navigate("/buyer-list");
  }

  return (
    <>
    <Header/>
      <div className="login-div">
      <Card style={{ width: 400 }}>
        <img className="login-logo" src={logo} width={200} alt=""></img>
        <h2 className="login-title">Giriş yap</h2>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
            <Form.Item
              name="taxId"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateVkn(value),
                },
              ]}>
              <Input className="w-100" placeholder="VKN/TCKN" />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
                    return patt.test(value) && value.length < 11
                      ? Promise.resolve()
                      : Promise.reject(
                        new Error(
                          'Lütfen geçerli bir telefon numarası giriniz.'
                        )
                      );
                  },
                },
              ]}>
              <Input addonBefore="+90" placeholder="TELEFON NUMARANIZ" />
            </Form.Item>
            {
              isInitialLogin ? (
                <div className='contract-modal'>
                  <Checkbox onClick={() => showModal(0)} > </Checkbox>
                  <span className="modal-lh">Üyelik Sözleşmesi</span>’ni, <span className="modal-lh">Aydınlatma Metni</span>'ni ve <span className="modal-lh"> Açık Rıza Metni</span>'ni okudum, anladım ve onaylıyorum. Kurumunuz ile paylaşmış olduğum iletişim bilgilerim ile elektronik iletiletilerin tarafımla paylaşılmasını onaylıyorum.
                  <Form.Item style={{ marginTop: '20px' }}>
                    <Button disabled={isInitialLogin} className={register ? 'register-btn' : ''} type="primary" htmlType="submit" size="large" loading={loading} block style={{ width: '100%' }}>
                      Giriş
                    </Button>
                  </Form.Item>
                </div>


              ) : (
                <Form.Item style={{ marginTop: '20px' }}>
                  <Button type="primary" htmlType="submit" size="large" loading={loading} block style={{ width: '100%' }}>
                    Giriş
                  </Button>
                </Form.Item>
              )
            }

          </Form>
      </Card>
    </div>
      <Modal className='contract-modal-style' 
          open={isModalOpen} 
          maskClosable={false}
          closable={false} 
          footer={[
            <Button key="back" type="primary"  onClick={handleStay}>
              Okudum Onayladım
            </Button>
          ]}
          >
        <Tabs activeKey={`${tabShow}`} onChange={onChangeTab}>
          <TabPane tab="Üyelik Sözleşmesi" key='0'>
            <MembershipAgreement />
          </TabPane>
          <TabPane tab="Aydınlatma Metni" key='1'>
            <ClarificationText />
          </TabPane>
          <TabPane tab="Açık Rıza Metni" key='2'>
            <ExplicitConsentText />
          </TabPane>
        </Tabs>
      </Modal>

      <Modal className='opt-modal-style' 
        footer={false} 
        open={otpModal} 
        maskClosable={false}
        onCancel={handleCancel} 
        style={{width:'100px'}}
        >
        <Form onFinish={onSubmitOtpCode}  form={form} style={{ textAlign: "center", marginTop:'40px' }}>
          <div className="otp-container">
            <p>Lütfen cep telefonunuza SMS olarak gönderilen 6 haneli kodu giriniz.</p>
            <Form.Item
              style={{ textAlign: "center", marginTop: "20px" }}
              name="otp"
              className="center-error-message"
              rules={[{ required: true, message: "Lütfen tüm değerleri giriniz!" }]}
            >
              <InputOTP inputType="numeric" length={6} value={null} />
            </Form.Item>
          </div>

          <Form.Item noStyle>
            <Button block htmlType="submit" className="login-btn" type="primary" style={{ marginTop: '20px', justifyContent: 'center' }}>
              Gönder
            </Button>
          </Form.Item>
        </Form> 
      </Modal>

    </>
  )
};
export default Login;
